import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const singlePage = ({ data }) => {
  const page = data.wpPage

  return (
    <Layout>
      <Seo title={page.title} />

      <article>
        <header>
          <h1 itemProp="headline">{parse(page.title)}</h1>
        </header>

        <div dangerouslySetInnerHTML={{ __html: page.content }} />

        <hr />
      </article>
    </Layout>
  )
}

export default singlePage

export const query = graphql`
  query($databaseId: Int!) {
    wpPage(databaseId: { eq: $databaseId }) {
      title
      content
    }
  }
`
